import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react";
import MiniDrawer from "../components/minidrawer"

function NavPageTitle(props) {
  const pt = props.pageTitle
  const st = props.siteTitle
  if (pt) {
    return pt
  } 
  return st
}

const Header = ({ siteTitle, pageTitle }) => (
  <header
    style={{
      background: `blue`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: `100%`,
        padding: `1.45rem 1.0875rem`,
      }}>
      <MiniDrawer>
        <h1 style={{ margin: 0 }}>
          <NavPageTitle siteTitle={siteTitle} pageTitle={pageTitle} />          
        </h1>
      </MiniDrawer>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Remote Work Tips`,
  pageTitle: ``,
}

export default Header
